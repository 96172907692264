<template>
    <div class="shop-personal">
        <div class="sp-con" v-if="userInfo">
            <div class="sp-con-title">账号设置</div>
            <div class="sp-con-item">
                <div class="tilte">账号：</div>
                <div class="val">{{userInfo.mobile}}</div>
            </div>

            <div class="sp-con-item">
                <div class="tilte">密码：</div>
                <div class="val">******</div>
                <a-button 
                    class="sp-con-update" 
                    type="link" 
                    @click="show.password = true">修改
                </a-button>
            </div>

            <div class="sp-con-item">
                <div class="tilte">姓名：</div>
                <div class="val" v-if="show.update !=1">{{userInfo.name}}</div>
                <a-input v-model:value="userInfo.name" v-if="show.update == 1">
                    <template #addonAfter>
                        <div style="cursor: pointer;" @click="sureUpdate">保存</div>
                    </template>
                </a-input>
                <a-button 
                    class="sp-con-update" 
                    v-if="show.update !=1" 
                    type="link" 
                    @click="show.update = 1">修改
                </a-button>
            </div>

            <div class="sp-con-item">
                <div class="tilte">绑定微信：</div>
                <div class="val"> {{ userInfo.openid ?'已绑定':'未绑定'}} </div>
                <a-button 
                    class="sp-con-update" 
                    type="link" 
                    @click="showBind">
                    {{ userInfo.openid ?'解绑':'去绑定'}}
                </a-button>
            </div>

            <div class="sp-con-item">
                <div class="tilte">邮箱：</div>
                <div class="val" v-if="show.update !=2">{{userInfo.email}}</div>
                <a-input v-model:value="userInfo.email" v-if="show.update == 2">
                    <template #addonAfter>
                        <div style="cursor: pointer;" @click="sureUpdate">保存</div>
                    </template>
                </a-input>
                <a-button 
                    class="sp-con-update" 
                    v-if="show.update !=2" 
                    type="link" 
                    @click="show.update = 2">修改
                </a-button>
            </div>

            <div class="sp-con-item">
                <div class="tilte">头像：</div>
                <div class="val">
                    <a-avatar :size="32" :src="userInfo.pic_url" />
                </div>
                <kd-img-select style="width:120px;" :custom="true" @success="updatePicUrl">
                    <a-button class="sp-con-update" type="link">修改</a-button>
                </kd-img-select>
            </div>
            <div class="sp-con-title"><a-button @click="$router.go(-1)">返回</a-button></div>
            
        </div> 

        <a-modal 
            v-model:visible="show.password" 
            title="修改密码" 
            width="600px" 
            @ok="savePassWord" 
            @cancel="show.password = false"
        >
			<a-form ref="form" :model="passwordForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="新密码">
                    <a-input v-model:value="passwordForm.password"></a-input>
                </a-form-item>
				<a-form-item label="再次新密码">
                    <a-input v-model:value="passwordForm.re_password"></a-input>
                </a-form-item>
			</a-form>
		</a-modal>

        <a-modal 
            v-model:visible="show.bind" 
            title="绑定微信" 
            width="600px" 
            :footer="null" 
            @cancel="show.bind = false"
        >
            <div v-if="userInfo && show.bindStep == 1">
                <div class="bind-title">身份验证：验证码将发送到管理员绑定的手机号（{{userInfo.mobile}}）,请注意查收</div>
                <a-space class="bind-info">
                    <a-input v-model:value="bindForm.code" style="width:240px"></a-input>
                    <com-send-mobile-code 
                        :mobile="userInfo.mobile" 
                        :code-type="4" 
                        @success="e=>bindForm.verify = e">
                    </com-send-mobile-code>
                </a-space>
                <a-space class="bind-info">
                    <a-button @click="show.bind = false">取消</a-button>
                    <a-button @click="bindOrUnbindWx" type="primary">立即{{ userInfo.openid ? '解绑':'绑定'}}</a-button>
                </a-space>
            </div>

            <div v-if="show.bindStep == 2 && wxLogin.appid" style="text-align:center;">
                <wxlogin 
                    style="margin-left: 25px" 
                    :appid="wxLogin.appid" 
                    :scope="wxLogin.scope"
                    :redirect_uri="wxLogin.redirect_uri">
                </wxlogin>
            </div>
		</a-modal>
    </div>
</template>
<script>
import comSendMobileCode from '@/components/func/com-send-mobile-code.vue'
import wxlogin from 'vue-wxlogin';

import userModel from '@/api/user.js'
import authModel from '@/api/saas/auth.js'
import { reactive, toRefs } from 'vue'
import loginModel from '@/api/login'
import { useRoute } from 'vue-router';

export default {
    components:{
        comSendMobileCode,
        wxlogin
    },
    setup() {
        const _d = reactive({
            userInfo:null,
            show:{
                password:false,
                update:0,   // 1修改姓名  2修改邮箱 3修改头像
                bind:false,
                bindStep:1,
            },
            passwordForm:{
                password:"",
                re_password:""
            },
            bindForm:{
                code:"",
                verify:"",
                data:null,
            },
            wxLogin:{
                appid:"",
                scope:"snsapi_login",
                redirect_uri:"",
            }
        })

        const options = useRoute().query
        if( options.code ){
            checkScan(options.code)
        }

        userModel.getUserDetail().then(res=>_d.userInfo = res)
        
        function sureUpdate(){
            let data = {
				pic_url: _d.userInfo.pic_url,
				name: _d.userInfo.name,
				mobile: _d.userInfo.mobile,
				email: _d.userInfo.email,
				user_id: _d.userInfo.user_id,
				type: 1,
				rank: 3,
				scene: 'edit',
				is_self: 1
			}
            userModel.addOrEditChildUser(data,()=>{_d.show.update = 0})
        }

        function savePassWord(){
            _d.passwordForm.user_id = _d.userInfo.user_id
            authModel.addOrEditEmployee('password',_d.passwordForm,()=>{
                _d.show.password = false
                _d.passwordForm.re_password = ""
                _d.passwordForm.password = ""
            })
        }

        function updatePicUrl(e){
            if( e && e != _d.userInfo.pic_url ){
                _d.userInfo.pic_url = e
                sureUpdate()
            }
        }

        function showBind(){
            if( !_d.userInfo.openid ){  //未绑定 显示绑定
                loginModel.getWxLoginConfig(res=>{
                    _d.wxLogin.appid = res.appid
                    _d.show.bindStep = 2
                    let domain = window.location.href.split(':')[0]+'://'
                    if( process.env.NODE_ENV  == 'development'){
                        _d.wxLogin.redirect_uri = encodeURIComponent( domain + 'farm.test.farmkd.com/login')
                    }else{
                        _d.wxLogin.redirect_uri = encodeURIComponent( domain + window.location.host+'/shop/personal')
                    }
                })
            }

            _d.show.bind = true
        }

        function bindOrUnbindWx(){
            //存在openid 解绑  不存在则绑定
            if( _d.userInfo.openid ){
                loginModel.unbindWx({mobile:_d.userInfo.mobile,..._d.bindForm},()=>{
                    userModel.getUserDetail().then(res=>_d.userInfo = res)
                    _d.show.bind = false
                    _d.bindForm = {
                        code:"",
                        verify:"",
                        data:null,
                    }
                })
            }else{
                loginModel.bindMobile({mobile:_d.userInfo.mobile,..._d.bindForm},'bind',()=>{
                    userModel.getUserDetail().then(res=>_d.userInfo = res)
                    _d.show.bind = false
                    _d.bindForm = {
                        code:"",
                        verify:"",
                        data:null,
                    }
                })
            }
        }

        function checkScan(code){
            loginModel.checkWxLogin('bind',code,res=>{
                if( !res.status ){
                    tool.message(res.msg)
                    return
                }
                //未绑定手机号
                if( res.bindMobile ){
                    _d.show.bind = true
                    _d.bindForm.data = res.data
                    return
                }
            })
        }

        return{
            ...toRefs(_d),
            sureUpdate,
            savePassWord,
            updatePicUrl,
            bindOrUnbindWx,
            showBind
        }
    },
}
</script>
<style lang="scss" scoped>
.sp-con{
    background: #fff;
    margin-top: 12px;
    padding: 24px;

    .upd-pass{
        color: #3399FF;
        cursor: pointer;
        margin-left: 12px;
    }

    &-title{
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        margin-top: 48px;
    }

    &-item{
        width: 60%;
        margin-left: 20%;
        display: flex;
        min-height: 48px;
        border-bottom: 1px solid #f4f4f4;
        position: relative;
        padding: 12px 0;

        .tilte{
            width: 80px;
            margin-right: 12px;
            color: #777;
        }

        .val{
            width: 300px;
        }
    }
    &-update{
        position: absolute;
        right: 0;
    }
}

.bind-info{
    width:100%;
    justify-content:center;
    margin-top: 24px;
}  

.bind-info:last-child{
    margin-top: 64px;
}
.bind-title{
    width: 100%;
    text-align: center;
}
</style>